import React, { useState } from 'react';
import { Container, Paper, Typography, Button, TextField } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import axios from 'axios';

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [affittiFile, setAffittiFile] = useState(null);
  const [bonoliFile, setBonoliFile] = useState(null);
  const [roselliFile, setRoselliFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [mese, setMese] = useState(0);
  const [spesa1, setSpesa1] = useState(0);
  const [spesa2, setSpesa2] = useState(0);
  const [spesa3, setSpesa3] = useState(0);
  const [spesa4, setSpesa4] = useState(0);
  const [spesa5, setSpesa5] = useState(0);
  const [ecFile, setECFile] = useState(null);
  const [ecFileUrl, setECFileUrl] = useState(null);
  const [pagamentiFileUrl, setPagamentiFileUrl] = useState(null);
  const [pagamentiFile, setPagamentiFile] = useState(null);
  const [pagamentiFile2, setPagamentiFile2] = useState(null);
  const [pagamentiFile3, setPagamentiFile3] = useState(null);
  const [pagamentiFile4, setPagamentiFile4] = useState(null);


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileUrl(null); // Reset fileUrl when a new file is selected
  };
  const handleAffittiChange = (event) => {
    setAffittiFile(event.target.files[0]);
    setFileUrl(null); 
  };
  const handleBonoliChange = (event) => {
    setBonoliFile(event.target.files[0]);
    setFileUrl(null);
  };
  const handleRoselliChange = (event) => {
    setRoselliFile(event.target.files[0]);
    setFileUrl(null);
  };
  const handleECChange = (event) => {
    setECFile(event.target.files[0]);
    setECFileUrl(null);
  };
  const handlePagamentiChange = (event) => {
    setPagamentiFile(event.target.files[0]);
    setPagamentiFileUrl(null);
  };
  const handlePagamentiChange2 = (event) => {
    setPagamentiFile2(event.target.files[0]);
  };
  const handlePagamentiChange3 = (event) => {
    setPagamentiFile3(event.target.files[0]);
  };
  const handlePagamentiChange4 = (event) => {
    setPagamentiFile4(event.target.files[0]);
  };
  const onChangeMese = (e) => {
      setMese(e.target.value)
  }
  const onChangeSpesa1 = (e) => {
      setSpesa1(e.target.value)
  }

  const handlePDFUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const response = await axios.post('/robi_be/split_pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          },
        responseType: 'blob', // This indicates that the response should be treated as a binary blob
      });

      // Extract the filename from the response headers
      const contentDisposition = response.headers['content-disposition'];
      const filenameMatch = contentDisposition.match(/filename="(.+)"/);
      const filename = filenameMatch ? filenameMatch[1] : 'response.txt';

      // Create a blob URL for the downloaded file
      const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = URL.createObjectURL(fileBlob);
      setFileUrl({ url, filename }); // Store the file URL and filename



      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleBonifici = async () => {

    try {
      const formData = new FormData();
      formData.append('roselli', roselliFile);
      formData.append('bonoli', bonoliFile);
      formData.append('affitti', affittiFile);
      formData.append('mese', mese);

      const response = await axios.post('/robi_be/add_bonifici', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          },
        responseType: 'blob', // This indicates that the response should be treated as a binary blob
      });

      // Extract the filename from the response headers
      console.log(JSON.stringify(response.headers));
      const contentDisposition = response.headers['content-disposition'];
      console.log(contentDisposition);
      const filenameMatch = contentDisposition.match(/filename=(.+)/);
      console.log(filenameMatch);
      const filename = filenameMatch ? filenameMatch[1] : 'response.xlsx';
      let regExp= /!/g;
      let report = response.headers['status-report'];
      console.log("status-report: " + report);
      if (report  != "")
        alert(report.replace(regExp,"\n"))

      // Create a blob URL for the downloaded file
      const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = URL.createObjectURL(fileBlob);
      setFileUrl({ url, filename }); // Store the file URL and filename



      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = filename;
      document.body.appendChild(link);
      // link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleECUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('ec_file', ecFile);

      const response = await axios.post('/robi_be/clean_ec', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          },
        responseType: 'blob', // This indicates that the response should be treated as a binary blob
      });

      // Extract the filename from the response headers
      const contentDisposition = response.headers['content-disposition'];
      console.log(contentDisposition)
      const filenameMatch = contentDisposition.match(/filename=(.+)/);
      var filename = filenameMatch ? filenameMatch[1] : 'ec.xlsx';
      filename = filename.replaceAll("\"", "")
      console.log('filename: ' + filename)
      let regExp= /!/g;
      let report = response.headers['status-report'];
      console.log("status-report: " + report);

      // Create a blob URL for the downloaded file
      const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = URL.createObjectURL(fileBlob);
      setECFileUrl({ url, filename }); // Store the file URL and filename


      // Create a temporary anchor element to trigger the download
      // const link = document.createElement('a');
      // link.href = fileUrl;
      // link.download = filename;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      if (report  != "")
        alert(report.replace(regExp,"\n"))
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handlePagamentiUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('pagamenti_file', pagamentiFile);
      formData.append('pagamenti_file2', pagamentiFile2);
      if (spesa1 != null)
        formData.append('spesa1', spesa1);
      if (spesa3 != null)
        formData.append('spesa2', spesa2);
      if (spesa3 != null)
        formData.append('spesa3', spesa3);
      if (spesa4 != null)
        formData.append('spesa4', spesa4);
      if (spesa5 != null)
        formData.append('spesa5', spesa5);

      const response = await axios.post('/robi_be/attribuzione_pagamenti', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          },
        responseType: 'blob', // This indicates that the response should be treated as a binary blob
      });

      // Extract the filename from the response headers
      const contentDisposition = response.headers['content-disposition'];
      console.log(contentDisposition)
      const filenameMatch = contentDisposition.match(/filename=(.+)/);
      var filename = filenameMatch ? filenameMatch[1] : 'ec.xlsx';
      filename = filename.replaceAll("\"", "")
      console.log('filename: ' + filename)
      let regExp= /!/g;
      let report = response.headers['status-report'];
      console.log("status-report: " + report);

      // Create a blob URL for the downloaded file
      const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = URL.createObjectURL(fileBlob);
      setPagamentiFileUrl({ url, filename }); // Store the file URL and filename

      if (report  != "")
        alert(report.replace(regExp,"\n"))
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
    <Container maxWidth="lg">
      <Paper elevation={3} style={{ padding: '16px', margin: '16px 0' }}>
        <Typography variant="h5" gutterBottom>
          Split PDF
        </Typography>
        <Typography variant="p" gutterBottom>
          Separa un file PDF multipagina, restituendo un file per ogni pagina
        </Typography>
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          style={{ marginBottom: '16px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUpload />}
          onClick={handlePDFUpload}
          disabled={!selectedFile}
        >
          Upload
        </Button>
        {fileUrl && (
          <div style={{ marginTop: '16px' }}>
            <Typography variant="body1">
              Backend Response:
            <a href={fileUrl.url} download={fileUrl.filename}>
              Download Response File
            </a>
            </Typography>
          </div>
        )}

      </Paper>
    </Container>
    <Container maxWidth="lg">
      <Paper elevation={3} style={{ padding: '16px', margin: '16px 0' }}>
        <Typography variant="h5" gutterBottom>
          Aggiungi un mese al file degli affitti
        </Typography>
        <Typography variant="p" gutterBottom>
          Aggiorna il report degli affitti (unico file roselli e bonoli), aggiungendo i movimenti correnti
        </Typography>
        <div><p></p></div>
        <Typography variant="p" gutterBottom>
          Devi caricare il report del mese precedente, e le liste movimenti bonoli e roselli
        </Typography>
        <div><p></p></div>
        <Typography variant="p" gutterBottom>
          Devi specificare il mese di riferimento, per poter caricare i dati nella colonna giusta
        </Typography>
        <div><p></p></div>
        <Typography variant="p" gutterBottom> Report Mese Precedente </Typography>
          <input type="file" accept=".xlsx" label="       file mese precedente" onChange={handleAffittiChange} style={{ marginBottom: '16px' }} />
        <div><p></p></div>
        <Typography variant="p" gutterBottom> File movimenti bonoli </Typography>
          <input type="file" accept=".xlsx" label="       File movimenti Bonoli" onChange={handleBonoliChange} style={{ marginBottom: '16px' }} />
        <div><p></p></div>
        <Typography variant="p" gutterBottom> File movimenti roselli </Typography>
          <input type="file" accept=".xlsx" label="       File movimenti Roselli" onChange={handleRoselliChange} style={{ marginBottom: '16px' }} />
        <div><p></p></div>
        <Typography variant="p" gutterBottom> Mese in numeri (1 ... 12) </Typography>
          <input type="text" name="mese" onChange={onChangeMese} />
        <div><p></p></div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUpload />}
          onClick={handleBonifici}
          disabled={!roselliFile}
        >
          Upload
        </Button>
        <div><p></p></div>
        {fileUrl && (
          <div style={{ marginTop: '16px' }}>
            <Typography variant="body1">
              Risposta dal server:
            <a href={fileUrl.url} download={fileUrl.filename}>
              Scarica il file aggiornato
            </a>
            </Typography>
          </div>
        )}

      </Paper>
    </Container>

    <Container maxWidth="lg">
      <Paper elevation={3} style={{ padding: '16px', margin: '16px 0' }}>
        <Typography variant="h5" gutterBottom> Pagamenti conduttori </Typography>
        <div><p></p></div>
        <Typography variant="p" gutterBottom>
          Dato un estratto conto Intesa, restituisce elenco dei pagamenti dei conduttori, ordinato per conduttore e data      
        </Typography>
        <div><p></p></div>
        <input type="file" accept=".xlsx" onChange={handleECChange} style={{ marginBottom: '16px' }} />
        <Button variant="contained" color="primary" startIcon={<CloudUpload />} onClick={handleECUpload} disabled={!ecFile} > Upload </Button>
        {ecFileUrl && ( <div style={{ marginTop: '16px' }}> <Typography variant="body1"> Backend Response: <a href={ecFileUrl.url} download={ecFileUrl.filename}> Scarica file pulito </a> </Typography> </div>)}

      </Paper>
    </Container>

    <Container maxWidth="lg">
      <Paper elevation={3} style={{ padding: '16px', margin: '16px 0' }}>
        <Typography variant="h5" gutterBottom> Attribuzione Pagamenti fatture </Typography>
        <div><p></p></div>
        <Typography variant="p" gutterBottom> Caricare i due estratti conto  (generale e corrente) in  XLS, propone una possibile intestazione dei costi in base a quanto pagato fino ad oggi</Typography>
        <div><p></p></div>
        <Typography variant="p" gutterBottom> Per il 2024 caricare i due file fino al 26/7 e quelli dal 27/7 in poi </Typography>
        <div><p></p></div>
        <input type="file" accept=".xlsx" onChange={handlePagamentiChange} style={{ marginBottom: '16px' }} />
        <input type="file" accept=".xlsx" onChange={handlePagamentiChange2} style={{ marginBottom: '16px' }} />
        <input type="file" accept=".xlsx" onChange={handlePagamentiChange3} style={{ marginBottom: '16px' }} />
        <input type="file" accept=".xlsx" onChange={handlePagamentiChange4} style={{ marginBottom: '16px' }} />
        <div><p></p></div>
        <Typography variant="p" gutterBottom> In questi spazi si  possono inserire fin a 4 spese da attribuire </Typography>
        <div><p></p></div>
        <Typography variant="p" gutterBottom> Spesa1  </Typography>
          <input type="text" name="spesa1" onChange={onChangeSpesa1} />
        <Typography variant="p" gutterBottom> Spesa2  </Typography>
        <input type="text" name="spesa2" onChange={(e) => setSpesa2(e.target.value)} />
        <Typography variant="p" gutterBottom> Spesa3  </Typography>
        <input type="text" name="spesa3" onChange={(e) => setSpesa3(e.target.value)} />
        <Typography variant="p" gutterBottom> Spesa4  </Typography>
        <input type="text" name="spesa4" onChange={(e) => setSpesa4(e.target.value)} />
        <div><p></p></div>
        <Button variant="contained" color="primary" startIcon={<CloudUpload />} onClick={handlePagamentiUpload} disabled={!pagamentiFile} > Upload </Button>
        {pagamentiFileUrl && ( <div style={{ marginTop: '16px' }}> <Typography variant="body1"> Backend Response: <a href={pagamentiFileUrl.url} download={pagamentiFileUrl.filename}> Scarica file pulito </a> </Typography> </div>)}

      </Paper>
    </Container>
    </div>
  );
}

export default App;

